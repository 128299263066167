import { Dropdown } from "primereact/dropdown";
import React from "react";

export default function Filter(props) {
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 170,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.filterSupers(e.value);
            } else {
              props.filterSupers(e.value);
            }
          }}
          value={props.selectedSuper}
          options={props.superData}
          optionLabel="username"
          placeholder="Filter Super"
          filter
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 170,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.filterMasters(e.value);
            } else {
              props.filterMasters(e.value);
            }
          }}
          value={props.selectedMaster}
          options={props.masterData}
          optionLabel="username"
          placeholder="Filter Master"
          filter
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 170,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.filterUsers(e.value);
            } else {
              props.filterUsers(e.value);
            }
          }}
          value={props.selectedUser}
          options={props.userData}
          optionLabel="username"
          placeholder="Filter Client"
          filter
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 200,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.filterScripts(e.value);
            } else {
              props.filterScripts(e.value);
            }
          }}
          value={props.selectedSymbol}
          options={props.scriptData}
          optionLabel="symbol"
          placeholder="Filter Symbol"
          filter
        />
      </div>
    </>
  );
}
