import React from "react";
import styled from "styled-components";
import TopbarMost from "../Common/TopbarMost";
import { NewTheme } from "../Theme/Theme";
import LiveTrade from "./LiveTrade/LiveTrade";
import Php from "../../Backend/Php";
import LiveUsers from "./LiveUsers/LiveUsers";
import RightHomePanel from "./LiveUsers/LiveUsers";
import { surveyPlaysound } from "../Notification/SoundUtility";
import NumberFormatIn from "../Common/NumberFormatIn";
import { Button } from "primereact/button";
import Marquee from "./Marquee";
import LiveSuperSummaryModal from "./Modal/LiveSuperSummaryModal";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

export default function Home() {
  const [dashboardData, setDashboardData] = React.useState([]);
  const [marquee, setMarquee] = React.useState([]);
  const [userActiveCard, setUserActiveCard] = React.useState(true);
  const [userSqOff, setUserSqOff] = React.useState(true);

  const initialShowNotification =
    JSON.parse(localStorage.getItem("showNotification")) || false;
  const [showNotification, setShowNotification] = React.useState(
    initialShowNotification
  );

  React.useEffect(() => {
    // Save showNotification to localStorage whenever it changes
    localStorage.setItem("showNotification", JSON.stringify(showNotification));
  }, [showNotification]);

  React.useEffect(() => {
    loadDashboard();
  }, []);

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const loadDashboard = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
    };

    php.load_dashboard(data).then((r) => {
      if (r.error == "False") {
        setDashboardData(r.dashboard);
        setMarquee(r.marquee);
      } else {
        logoutFunc();
      }
    });
  };

  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] =
    React.useState(false);
  const [position, setPosition] = React.useState("top");

  const showLiveSummaryModal = (position) => {
    setPosition("top");
    setVisibleLiveSummaryModal(true);
  };

  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Supers</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: NewTheme.MainColor,
          }}
        >
          {dashboardData?.supers}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Masters</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: NewTheme.MainColor,
          }}
        >
          {dashboardData?.masters}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Clients</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "#ffa500",
          }}
        >
          {dashboardData?.users}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Daily Turnover</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.daily_volume >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.daily_volume).toFixed(0)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Weekly TurnOver</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.weekly_volume >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.weekly_volume).toFixed(0)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Daily P/L</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.daily_profit_loss >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.daily_profit_loss).toFixed(2)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Weekly P/L</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color:
              dashboardData?.weekly_profit_loss >= 0
                ? NewTheme.greencolor
                : NewTheme.redcolor,
          }}
        >
          <NumberFormatIn
            value={parseFloat(dashboardData?.weekly_profit_loss).toFixed(2)}
          />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          icon="pi pi-bell"
          label={showNotification ? "HIDE" : "SHOW"}
          severity={showNotification ? "danger" : "info"}
          style={{
            height: 30,
            // width: 30,
            fontSize: 12,
            padding: 10,
          }}
          onClick={() => setShowNotification(!showNotification)}
          data-pr-tooltip="XLS"
        />
      </div>
    </div>
  );

  return (
    <>
      <TopbarMost
        name={
          localStorage.getItem("server").toUpperCase() +
          " HOME | " +
          localStorage.getItem("adminsUsername")?.toUpperCase()
        }
        cmp={topworkui}
      />
      <Marquee
        showLiveSummaryModal={() => showLiveSummaryModal()}
        marquee={marquee}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: totalHeight,
          padding: 10,
        }}
      >
        <div style={{ flex: 5 }}>
          <LiveTrade height={height - 70} />
        </div>
        {showNotification ? (
          <div
            style={{
              flex: 1,
              marginLeft: 5,
              // height: "100%",
              // borderStyle: "solid",
              display: "flex",
              height: totalHeight - 60,
              flex: userActiveCard ? 1 : userSqOff ? 1 : 0,
              flexDirection: "column",
              gap: 10,
              // backgroundColor: NewTheme.lightmain,
            }}
          >
            {userActiveCard ? <RightHomePanel height={height - 70} /> : null}
          </div>
        ) : null}
      </div>
      {visibleLiveSummaryModal ? (
        <LiveSuperSummaryModal
          key={new Date()}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          weekly_pl={parseFloat(dashboardData?.weekly_profit_loss).toFixed(2)}
          clientUsername={localStorage.getItem("adminsUsername")?.toUpperCase()}
        />
      ) : null}
    </>
  );
}
