import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { faL } from "@fortawesome/free-solid-svg-icons";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const BalanceUpdateButton = styled.div`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Brokerage(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [adminBrokearge, setAdminBrokearge] = React.useState([]);
  const [error, setError] = React.useState("");

  const [maxFutBrokerage, setMaxFutBrokerage] = React.useState("");
  const [minFutBrokerage, setMinFutBrokerage] = React.useState("");
  const [maxFoBrokerage, setMaxFoBrokerage] = React.useState("");
  const [minFoBrokerage, setMinFoBrokerage] = React.useState("");
  const [maxMcxBrokerage, setMaxMcxBrokerage] = React.useState("");
  const [minMcxBrokerage, setMinMcxBrokerage] = React.useState("");
  const [maxMcxCrBrokerage, setMaxMcxCrBrokerage] = React.useState("");
  const [minMcxCrBrokerage, setMinMcxCrBrokerage] = React.useState("");

  React.useEffect(() => {
    load_master_brokerages();
    load_super_brokerages();
  }, []);

  const load_super_brokerages = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        s_id: props.data.super_id,
      };

      php.load_super_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setAdminBrokearge(r.supers_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const load_master_brokerages = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        m_id: props.data.id,
      };

      php.load_master_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setMaxFutBrokerage(r.masters_brokerage.max_fut_brokerage);
          setMinFutBrokerage(r.masters_brokerage.min_fut_brokerage);
          setMaxFoBrokerage(r.masters_brokerage.max_fo_brokerage);
          setMinFoBrokerage(r.masters_brokerage.min_fo_brokerage);
          setMaxMcxBrokerage(r.masters_brokerage.max_mcx_brokerage);
          setMinMcxBrokerage(r.masters_brokerage.min_mcx_brokerage);
          setMaxMcxCrBrokerage(r.masters_brokerage.max_cr_mcx_brokerage);
          setMinMcxCrBrokerage(r.masters_brokerage.min_cr_mcx_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const edit_master_brokerage = (e) => {
    setError("");
    if (
      parseFloat(minFutBrokerage) <
        parseFloat(adminBrokearge.min_fut_brokerage) ||
      parseFloat(minFutBrokerage) > parseFloat(adminBrokearge.max_fut_brokerage)
    ) {
      setError("Min Future Brokerage");
      addNotification(
        "Enter Min Future Brokerage Between " +
          adminBrokearge.min_fut_brokerage +
          " & " +
          adminBrokearge.max_fut_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxFutBrokerage) <
        parseFloat(adminBrokearge.min_fut_brokerage) ||
      parseFloat(maxFutBrokerage) > parseFloat(adminBrokearge.max_fut_brokerage)
    ) {
      setError("Max Future Brokerage");
      addNotification(
        "Enter Max Future Brokerage Between " +
          adminBrokearge.min_fut_brokerage +
          " & " +
          adminBrokearge.max_fut_brokerage,
        "error"
      );
    } else if (parseFloat(maxFutBrokerage) < parseFloat(minFutBrokerage)) {
      setError("Max Future Brokerage");
      addNotification(
        "Enter Max Future Brokerage More Than " + minFutBrokerage,
        "error"
      );
    } else if (
      ///mcx
      parseFloat(minMcxBrokerage) <
        parseFloat(adminBrokearge.min_mcx_brokerage) ||
      parseFloat(minMcxBrokerage) > parseFloat(adminBrokearge.max_mcx_brokerage)
    ) {
      setError("Min Mcx Brokerage");
      addNotification(
        "Enter Min Mcx Brokerage Between " +
          adminBrokearge.min_mcx_brokerage +
          " & " +
          adminBrokearge.max_mcx_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxMcxBrokerage) <
        parseFloat(adminBrokearge.min_mcx_brokerage) ||
      parseFloat(maxMcxBrokerage) > parseFloat(adminBrokearge.max_mcx_brokerage)
    ) {
      setError("Max Mcx Brokerage");
      addNotification(
        "Enter Max Mcx Brokerage Between " +
          adminBrokearge.min_mcx_brokerage +
          " & " +
          adminBrokearge.max_mcx_brokerage,
        "error"
      );
    } else if (parseFloat(maxMcxBrokerage) < parseFloat(minMcxBrokerage)) {
      setError("Max Mcx Brokerage");
      addNotification(
        "Enter Max Mcx Brokerage More Than " + minMcxBrokerage,
        "error"
      );
    } else if (
      ///mcx cr wise
      parseFloat(minMcxCrBrokerage) <
        parseFloat(adminBrokearge.min_cr_mcx_brokerage) ||
      parseFloat(minMcxCrBrokerage) >
        parseFloat(adminBrokearge.max_cr_mcx_brokerage)
    ) {
      setError("Min Mcx Brokerage Cr");
      addNotification(
        "Enter Min Mcx Brokerage (Cr) Between " +
          adminBrokearge.min_cr_mcx_brokerage +
          " & " +
          adminBrokearge.max_cr_mcx_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxMcxCrBrokerage) <
        parseFloat(adminBrokearge.min_cr_mcx_brokerage) ||
      parseFloat(maxMcxCrBrokerage) >
        parseFloat(adminBrokearge.max_cr_mcx_brokerage)
    ) {
      setError("Max Mcx Brokerage Cr");
      addNotification(
        "Enter Max Mcx Brokerage (Cr) Between " +
          adminBrokearge.min_cr_mcx_brokerage +
          " & " +
          adminBrokearge.max_cr_mcx_brokerage,
        "error"
      );
    } else if (parseFloat(maxMcxCrBrokerage) < parseFloat(minMcxCrBrokerage)) {
      setError("Max Mcx Brokerage Cr");
      addNotification(
        "Enter Max Mcx Brokerage (Cr) More Than " + minMcxCrBrokerage,
        "error"
      );
    } else if (
      ///options
      parseFloat(minFoBrokerage) <
        parseFloat(adminBrokearge.min_fo_brokerage) ||
      parseFloat(minFoBrokerage) > parseFloat(adminBrokearge.max_fo_brokerage)
    ) {
      setError("Min Option Brokerage");
      addNotification(
        "Enter Min Option Brokerage Between " +
          adminBrokearge.min_fo_brokerage +
          " & " +
          adminBrokearge.max_fo_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxFoBrokerage) <
        parseFloat(adminBrokearge.min_fo_brokerage) ||
      parseFloat(maxFoBrokerage) > parseFloat(adminBrokearge.max_fo_brokerage)
    ) {
      setError("Max Option Brokerage");
      addNotification(
        "Enter Max Option Brokerage Between " +
          adminBrokearge.min_fo_brokerage +
          " & " +
          adminBrokearge.max_fo_brokerage,
        "error"
      );
    } else if (parseFloat(maxFoBrokerage) < parseFloat(minFoBrokerage)) {
      setError("Max Option Brokerage");
      addNotification(
        "Enter Max Option Brokerage More Than " + minFoBrokerage,
        "error"
      );
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          m_id: props.data.id,
          max_fut_brokerage: parseFloat(maxFutBrokerage).toFixed(3),
          min_fut_brokerage: parseFloat(minFutBrokerage).toFixed(3),
          max_fo_brokerage: parseFloat(maxFoBrokerage).toFixed(0),
          min_fo_brokerage: parseFloat(minFoBrokerage).toFixed(0),
          max_mcx_brokerage: parseFloat(maxMcxBrokerage).toFixed(0),
          min_mcx_brokerage: parseFloat(minMcxBrokerage).toFixed(0),
          max_mcx_cr_brokerage: parseFloat(maxMcxCrBrokerage).toFixed(3),
          min_mcx_cr_brokerage: parseFloat(minMcxCrBrokerage).toFixed(3),
        };

        php.edit_master_brokerage(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            load_master_brokerages();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card
        style={{
          margin: 10,
          position: "relative",
          height: "calc(100% - (60px - 1px))",
          overflowY: "scroll",
        }}
      >
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel>Min Future Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_fut_brokerage}</label>
                </span>
                <InputText
                  value={minFutBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Future Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFutBrokerage(e.target.value)}
                  placeholder="Enter Min Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{adminBrokearge.max_fut_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Future Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_fut_brokerage}</label>
                </span>
                <InputText
                  value={maxFutBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFutBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.max_fut_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min Mcx Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_mcx_brokerage}</label>
                </span>
                <InputText
                  value={minMcxBrokerage}
                  type="number"
                  style={{
                    border: error == "Min Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinMcxBrokerage(e.target.value)}
                  placeholder="Enter Min Mcx Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.max_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Mcx Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_mcx_brokerage}</label>
                </span>
                <InputText
                  value={maxMcxBrokerage}
                  type="number"
                  style={{
                    border: error == "Max Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxMcxBrokerage(e.target.value)}
                  placeholder="Enter Max Mcx Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.max_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min Mcx Brokerage (Cr)</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_cr_mcx_brokerage}</label>
                </span>
                <InputText
                  value={minMcxCrBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Mcx Brokerage Cr" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinMcxCrBrokerage(e.target.value)}
                  placeholder="Enter Min Mcx Brokerage Cr"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.max_cr_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Mcx Brokerage (Cr)</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_cr_mcx_brokerage}</label>
                </span>
                <InputText
                  value={maxMcxCrBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Mcx Brokerage Cr" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxMcxCrBrokerage(e.target.value)}
                  placeholder="Enter Max Mcx Brokerage Cr"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.max_cr_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min Option Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_fo_brokerage}</label>
                </span>
                <InputText
                  value={minFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFoBrokerage(e.target.value)}
                  placeholder="Enter Min Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.max_fo_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Option Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{adminBrokearge.min_fo_brokerage}</label>
                </span>
                <InputText
                  value={maxFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFoBrokerage(e.target.value)}
                  placeholder="Enter Max Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{adminBrokearge.max_fo_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
          </BalanceWrapper>
          <BalanceUpdateButton onClick={edit_master_brokerage}>
            Save
          </BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
