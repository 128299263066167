import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { BoldText, FlexItem } from "../../Users/Overview/Styled";
import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();

export default function BrokerageModal({
  visible,
  position,
  setVisible,
  fut,
  mcx,
  fo,
  forex,
  crypto,
  stocks,
  reload,
}) {
  const [futBrokerage, setFutBrokerage] = React.useState(fut);
  const [mcxBrokerage, setMcxBrokerage] = React.useState(mcx);
  const [foBrokerage, setFoBrokerage] = React.useState(fo);
  const [forexBrokerage, setForexBrokerage] = React.useState(forex);
  const [cryptoBrokerage, setCryptoBrokerage] = React.useState(crypto);
  const [stocksBrokerage, setStocksBrokerage] = React.useState(stocks);
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const load_master_brokerage_min_max = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.load_master_brokerage_min_max(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
        } else {
          alert(r.message);
        }
      });
    }
  };

  function isValidBrokerage(value) {
    return value !== "" && value !== null && value !== undefined && value > 0;
  }

  const change_default_brokerage = () => {
    if (!isValidBrokerage(futBrokerage)) {
      alert("Please enter valid FUTURE Brokerages");
    } else if (!isValidBrokerage(mcxBrokerage)) {
      alert("Please enter valid MCX Brokerages");
    } else if (!isValidBrokerage(foBrokerage)) {
      alert("Please enter valid OPTIONS Brokerages");
    } else if (!isValidBrokerage(forexBrokerage)) {
      alert("Please enter valid FOREX Brokerages");
    } else if (!isValidBrokerage(cryptoBrokerage)) {
      alert("Please enter valid CRYPTO Brokerages");
    } else if (!isValidBrokerage(stocksBrokerage)) {
      alert("Please enter valid US STOCKS Brokerages");
    } else {
      if (!loading) {
        setLoading(true);
        let data2 = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          m_id: localStorage.getItem("mastersId"),
          fut_brokerage: futBrokerage,
          mcx_brokerage: mcxBrokerage,
          fo_brokerage: foBrokerage,
          forex_brokerage: forexBrokerage,
          crypto_brokerage: cryptoBrokerage,
          stocks_brokerage: stocksBrokerage,
        };

        php.change_default_brokerage(data2).then((r) => {
          console.log(r);
          setLoading(false);
          if (r.error == "False") {
            reload();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => change_default_brokerage()}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>Edit Default Brokerage</div>
      }
      visible={visible}
      position={position}
      style={{ width: "35vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Future</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={futBrokerage}
          onChange={(e) => setFutBrokerage(e.target.value)}
          placeholder="Enter Future Brokerage"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Mcx</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={mcxBrokerage}
          onChange={(e) => setMcxBrokerage(e.target.value)}
          placeholder="Enter Mcx Brokerage"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Options</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={foBrokerage}
          onChange={(e) => setFoBrokerage(e.target.value)}
          placeholder="Enter Option Brokerage"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Forex</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={forexBrokerage}
          onChange={(e) => setForexBrokerage(e.target.value)}
          placeholder="Enter Forex Brokerage"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Crypto</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={cryptoBrokerage}
          onChange={(e) => setCryptoBrokerage(e.target.value)}
          placeholder="Enter Crypto Brokerage"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>US STOCKS</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        {/* <span className="p-inputgroup-addon">{props.min_multiplier}</span> */}
        <InputText
          value={stocksBrokerage}
          onChange={(e) => setStocksBrokerage(e.target.value)}
          placeholder="Enter US STOCKS Brokerage"
        />
        {/* <span className="p-inputgroup-addon">{props.max_multiplier}</span> */}
      </div>
    </Dialog>
  );
}
