import { POST } from ".";

const errorHandle = (apiUrl, apiData) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await POST(apiUrl, apiData);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const loginApi = (data) => errorHandle("/admin_login", data);
