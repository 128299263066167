import React from "react";
import TopbarMost from "../Common/TopbarMost";
import styled from "styled-components";
import RightSide from "./RightSide";
import Php from "../../Backend/Php";
import { CreateClientButton } from "../Users/Overview/Styled";
import { NewTheme } from "../Theme/Theme";

const php = new Php();

const DashboardContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  height: calc(
    100vh - 60px
  ); /* Subtract the top menu bar height from the viewport height */
`;

const RightPanel = styled.div`
  flex: 3; /* Takes up 3/4 of the width */
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 100%; /* Set the maximum height to 100% of the adjusted viewport height */
`;

export default function Profile() {
  const openRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const admin_lock = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
      };

      php.admin_lock(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          localStorage.clear();
          window.location = "/";
        } else {
          alert(r.message, "error");
        }
      });
    }
  };

  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <CreateClientButton
          active={true}
          color={NewTheme.redcolor}
          onClick={() => admin_lock()}
        >
          {"E-LOCK"}
        </CreateClientButton>
      </div>
    </div>
  );
  return (
    <>
      <TopbarMost name={"Profile"} cmp={topworkui} />
      <DashboardContainer>
        <RightPanel>
          <RightSide openRef={openRef} />
        </RightPanel>
      </DashboardContainer>
    </>
  );
}
