import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { NewTheme } from "../../Theme/Theme";
import {
  BoldText,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
} from "../../Users/Overview/Styled";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import NumberFormatIn from "../../Common/NumberFormatIn";
import styled from "styled-components";

const php = new Php();

const Placeholder = styled.div`
  width: 100px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #e0e0e0; /* Light gray color */
  border-radius: 4px;
  animation: shimmer 1.5s infinite;

  @keyframes shimmer {
    0% {
      background-position: -100px 0;
    }
    100% {
      background-position: 100px 0;
    }
  }
`;

export default function LiveSuperSummaryModal(props) {
  const { addNotification } = useNotification();
  const [activeSummary, setActiveSummary] = useState("");
  const [totalPL, setTotalPL] = useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    loadActiveSummary();
  }, []);

  const loadActiveSummary = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      a_id: localStorage.getItem("adminsId"),
    };

    php.load_admin_active_summary(data).then((r) => {
      if (r.error == "False") {
        setActiveSummary(r.data);
        let PL = parseFloat(props.weekly_pl) + parseFloat(r.data);

        setTotalPL(parseFloat(PL).toFixed(0));
      } else {
        addNotification(r.message, "error");
      }
      setLoading(false);
    });
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      {/* <Button
        label="PDF"
        icon="pi pi-download"
        onClick={() => alert("PDF")}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      /> */}
    </div>
  );
  const data = [
    { label: "Booked P/L", value: "weekly_pl", format: false },
    { label: "Live P/L", value: "activeSummary", format: true },
    { label: "Total P/L", value: "totalPL", format: true },
  ];
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"  Client Live Summary : "}
          {props.clientUsername != undefined ? props.clientUsername : null}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexContainer>
        {data.map((item, index) => {
          const value =
            item.value === "weekly_pl"
              ? props.weekly_pl
              : item.value === "activeSummary"
              ? activeSummary
              : totalPL;

          const formattedValue =
            value != null && item.format ? parseFloat(value).toFixed(2) : value;

          return (
            <FlexItem key={index}>
              <GrayText>{item.label}</GrayText>
              {loading ? (
                <Placeholder />
              ) : (
                <GreenText
                  style={{
                    color: value >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
                  }}
                >
                  <NumberFormatIn value={formattedValue || 0} />
                </GreenText>
              )}
            </FlexItem>
          );
        })}
      </FlexContainer>
    </Dialog>
  );
}
